import * as Matter from "matter-js"
import p5 from "p5"
import type p5w from "p5"
import Home from "../pages/Home"
import { pxToRem } from "../utils"
import Word from "./p5-word"
export type p5x = p5w & { font: p5.Font; bg: p5.Color }
import gsap from "gsap"
import { $App } from ".."

export default class p5Text {
  Engine: typeof Matter.Engine
  world: typeof Matter.World
  bodies: typeof Matter.Bodies
  font: any
  p5: p5
  element: HTMLElement
  app: $App
  constructor(app: $App) {
    this.app = app
    this.Engine = Matter.Engine
    this.world = Matter.World
    this.bodies = Matter.Bodies
    this.element = window.$(".home__hero__canvas")

    let engine: Matter.Engine
    let words: Word[] = []
    let ceil:
      | Matter.Composite
      | Matter.Body
      | Matter.Constraint
      | Matter.MouseConstraint
    let ground:
      | Matter.Composite
      | Matter.Body
      | Matter.Constraint
      | Matter.MouseConstraint
    let wallLeft:
      | Matter.Composite
      | Matter.Body
      | Matter.Constraint
      | Matter.MouseConstraint
    let wallRight:
      | Matter.Composite
      | Matter.Body
      | Matter.Constraint
      | Matter.MouseConstraint
    let wordstoDisplay = [
      "copywriter",
      "writer & editor",
      "SEO specialist",
      "product marketer",
      "email marketer",
      "content strategist",
      "storyteller",
      "producer",
      "director",
    ]

    let updateEngine: boolean = false

    gsap.delayedCall(2.5, () => {
      updateEngine = true
    })

    const s = (sketch: p5x) => {
      sketch.preload = () => {
        this.font = this.p5.loadFont("/fonts/Inter-Medium.otf")
      }

      sketch.setup = () => {
        this.p5.width = pxToRem(window.innerWidth < 760 ? 450 : 1440)
        this.p5.height = pxToRem(window.innerWidth < 760 ? 450 : 600)
        sketch.createCanvas(
          this.p5.width,
          this.p5.height,
          this.p5.P2D,
          this.element
        )
        engine = this.Engine.create()
        ceil = this.bodies.rectangle(
          this.p5.width / 2,
          pxToRem(window.innerWidth < 768 ? 10 : 50),
          this.p5.width,
          pxToRem(10),
          {
            isStatic: true,
          }
        )
        ground = this.bodies.rectangle(
          this.p5.width / 2,
          this.p5.height - pxToRem(window.innerWidth < 768 ? 30 : 50),
          this.p5.width,
          pxToRem(10),
          { isStatic: true }
        )
        wallLeft = this.bodies.rectangle(
          50,
          this.p5.height / 2,
          10,
          this.p5.height,
          { isStatic: true }
        )
        wallRight = this.bodies.rectangle(
          this.p5.width - pxToRem(50),
          this.p5.height / 2,
          pxToRem(10),
          this.p5.height,
          { isStatic: true }
        )

        this.world.add(engine.world, [ceil, ground, wallLeft, wallRight])

        sketch.mouseDragged = () => {
          for (let word of words) {
            if (
              this.p5.dist(
                this.p5.mouseX,
                this.p5.mouseY,
                word.body.position.x,
                word.body.position.y
              ) < pxToRem(50)
            ) {
              Matter.Body.applyForce(
                word.body,
                {
                  x: word.body.position.x,
                  y: word.body.position.y,
                },
                {
                  x:
                    innerWidth < 768
                      ? this.p5.random(pxToRem(-0.075), pxToRem(0.075))
                      : this.p5.random(pxToRem(-0.15), pxToRem(0.15)),
                  y:
                    innerWidth < 768
                      ? this.p5.random(pxToRem(-0.05), pxToRem(0))
                      : this.p5.random(pxToRem(-0.1), pxToRem(0)),
                }
              )
            }
          }
        }

        sketch.doubleClicked = () => {
          for (let word of words) {
            if (
              this.p5.dist(
                this.p5.mouseX,
                this.p5.mouseY,
                word.body.position.x,
                word.body.position.y
              ) < pxToRem(50)
            ) {
              word.index = (word.index + 1) % 8
            }
          }
        }

        sketch.mouseMoved = () => {
          for (let word of words) {
            if (
              this.p5.dist(
                this.p5.mouseX,
                this.p5.mouseY,
                word.body.position.x,
                word.body.position.y
              ) < pxToRem(50)
            ) {
              this.element.classList.add("active")
              return
            }
          }
          this.element.classList.remove("active")
          this.element.classList.remove("active2")
        }

        sketch.mousePressed = () => {
          for (let word of words) {
            if (
              this.p5.dist(
                this.p5.mouseX,
                this.p5.mouseY,
                word.body.position.x,
                word.body.position.y
              ) < pxToRem(50)
            ) {
              this.element.classList.add("active2")
              return
            }
          }
          this.element.classList.remove("active2")
        }

        for (let i = 0; i < wordstoDisplay.length; i++) {
          words.push(
            new Word(
              wordstoDisplay[i],
              i,
              this.p5,
              this.world,
              engine,
              this.font
            )
          )
        }
      }

      let removed = false
      sketch.draw = () => {
        sketch.clear()
        for (let word of words) {
          word.show()
        }
        if (!removed) {
          window.$(".app").classList.remove("preloading")
          removed = true
        }
        updateEngine && this.Engine.update(engine)
      }

      Matter.Events.on(this.Engine, "beforeUpdate", (e) => {
        for (let word of words) {
          let maxSpeed = innerWidth < 768 ? 0.1 : 1
          if (word.body.velocity.x > maxSpeed) {
            Matter.Body.setVelocity(word.body, {
              x: maxSpeed,
              y: word.body.velocity.y,
            })
          }

          if (word.body.velocity.x < -maxSpeed) {
            Matter.Body.setVelocity(word.body, {
              x: -maxSpeed,
              y: word.body.velocity.y,
            })
          }

          if (word.body.velocity.y > maxSpeed) {
            Matter.Body.setVelocity(word.body, {
              x: word.body.velocity.x,
              y: maxSpeed,
            })
          }

          if (word.body.velocity.y < -maxSpeed) {
            Matter.Body.setVelocity(word.body, {
              x: -word.body.velocity.x,
              y: -maxSpeed,
            })
          }
        }
      })
    }

    this.p5 = new p5(s)
  }
}
