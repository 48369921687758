import gsap from "gsap"

export default function () {
  const mm = gsap.matchMedia()
  let tl: gsap.core.Timeline
  tl = gsap.timeline()
  mm.add("(min-width:761px) and (max-width:1919px)", () => {
    tl = gsap
      .timeline()
      .fromTo(".home__testimonial__controls svg:nth-of-type(1)", { autoAlpha: 0 }, { autoAlpha: 1 })
      .fromTo(
        ".home__testimonies",
        {
          x: "347.35rem",
        },
        {
          x: "279.35rem",
          duration: 0.3,
          ease: "none",
        },
        "<"
      )
      .addPause()
      .to(".home__testimonies", {
        x: "211.35rem",
        duration: 0.3,
        ease: "none",
      })
      .addPause()
      .to(".home__testimonies", {
        x: "141.35rem",
        duration: 0.3,
        ease: "none",
      })
      .addPause()
      .to(".home__testimonies", {
        x: "70.35rem",
        duration: 0.3,
        ease: "none",
      })
      .addPause()
      .to(".home__testimonies", {
        x: "1.35rem",
        duration: 0.3,
        ease: "none",
      })
      .addPause()
      .to(".home__testimonies", {
        x: "-68.65rem",
        duration: 0.3,
        ease: "none",
      })
      .addPause()
      .to(".home__testimonies", {
        x: "-139.15rem",
        duration: 0.3,
        ease: "none",
      })
      .addPause()
      .to(".home__testimonies", {
        x: "-209.15rem",
        duration: 0.3,
        ease: "none",
      })
      .addPause()
      .to(".home__testimonies", {
        x: "-279.15rem",
        duration: 0.3,
        ease: "none",
      })
      .to(".home__testimonial__controls svg:nth-of-type(2)", { autoAlpha: 0 }, "<")
  })

  mm.add("(min-width:1920px)", () => {
    tl = gsap
      .timeline()
      .fromTo(".home__testimonial__controls svg:nth-of-type(1)", { autoAlpha: 0 }, { autoAlpha: 1 })
      .fromTo(
        ".home__testimonies",
        {
          x: "347.35rem",
        },
        {
          x: "279.35rem",
          duration: 0.3,
          ease: "none",
        },
        "<"
      )
      .addPause()
      .to(".home__testimonies", {
        x: "211.35rem",
        duration: 0.3,
        ease: "none",
      })
      .addPause()
      .to(".home__testimonies", {
        x: "141.35rem",
        duration: 0.3,
        ease: "none",
      })
      .addPause()
      .to(".home__testimonies", {
        x: "70.35rem",
        duration: 0.3,
        ease: "none",
      })
      .addPause()
      .to(".home__testimonies", {
        x: "1.35rem",
        duration: 0.3,
        ease: "none",
      })
      .addPause()
      .to(".home__testimonies", {
        x: "-68.65rem",
        duration: 0.3,
        ease: "none",
      })
      .addPause()
      .to(".home__testimonies", {
        x: "-139.15rem",
        duration: 0.3,
        ease: "none",
      })
      .addPause()
      .to(".home__testimonies", {
        x: "-209.15rem",
        duration: 0.3,
        ease: "none",
      })
      .addPause()
      .to(".home__testimonies", {
        x: "-279.15rem",
        duration: 0.3,
        ease: "none",
      })
      .to(".home__testimonial__controls svg:nth-of-type(2)", { autoAlpha: 0 }, "<")
  })

  mm.add("(max-width:760px)", () => {
    tl = gsap
      .timeline()
      .fromTo(".home__testimonial__controls svg:nth-of-type(1)", { autoAlpha: 0 }, { autoAlpha: 1 })
      .fromTo(
        ".home__testimonies",
        {
          x: "188.95rem",
        },
        {
          x: "151.25rem",
          duration: 0.3,
          ease: "none",
        },
        "<"
      )
      .addPause()
      .to(".home__testimonies", {
        x: "113.55rem",
        duration: 0.3,
        ease: "none",
      })
      .addPause()
      .to(".home__testimonies", {
        x: "75.45rem",
        duration: 0.3,
        ease: "none",
      })
      .addPause()
      .to(".home__testimonies", {
        x: "37.75rem",
        duration: 0.3,
        ease: "none",
      })
      .addPause()
      .to(".home__testimonies", {
        x: "0.15rem",
        duration: 0.3,
        ease: "none",
      })
      .addPause()
      .to(".home__testimonies", {
        x: "-37.65rem",
        duration: 0.3,
        ease: "none",
      })
      .addPause()
      .to(".home__testimonies", {
        x: "-75.35rem",
        duration: 0.3,
        ease: "none",
      })
      .addPause()
      .to(".home__testimonies", {
        x: "-113.15rem",
        duration: 0.3,
        ease: "none",
      })
      .addPause()
      .to(".home__testimonies", {
        x: "-151.05rem",
        duration: 0.3,
        ease: "none",
      })
      .to(".home__testimonial__controls svg:nth-of-type(2)", { autoAlpha: 0 }, "<")
  })

  return tl
}
