import Matter from "matter-js"
import { pxToRem } from "../utils"
import p5, { Font } from "p5"
import type p5w from "p5"
export type p5x = p5w & { font: p5.Font; bg: p5.Color }

let coords = [
  {
    x: pxToRem(window.innerWidth < 768 ? 91 : 157),
    y: pxToRem(window.innerWidth < 768 ? 70 : 173),
    deg: -5.63,
    width: 101,
  },
  {
    x: pxToRem(window.innerWidth < 768 ? 135 : 256.5),
    y: pxToRem(window.innerWidth < 768 ? 157 : 370),
    deg: -5.63,
    width: 126,
  },
  {
    x: pxToRem(window.innerWidth < 768 ? 215 : 967),
    y: pxToRem(window.innerWidth < 768 ? 362.5 : 410),
    deg: 10.047,
    width: 136,
  },
  {
    x: pxToRem(window.innerWidth < 768 ? 230 : 447),
    y: pxToRem(window.innerWidth < 768 ? 185 : 443),
    deg: -7.52,
    width: 161,
  },
  {
    x: pxToRem(window.innerWidth < 768 ? 282 : 1107),
    y: pxToRem(window.innerWidth < 768 ? 282 : 245),
    deg: 7.518,
    width: 138,
  },
  {
    x: pxToRem(window.innerWidth < 768 ? 227 : 453),
    y: pxToRem(window.innerWidth < 768 ? 105 : 260),
    deg: 5.885,
    width: 164,
  },
  {
    x: pxToRem(window.innerWidth < 768 ? 335 : 1225),
    y: pxToRem(window.innerWidth < 768 ? 367 : 415),
    deg: -5.633,
    width: 93,
  },

  {
    x: pxToRem(window.innerWidth < 768 ? 155 : 830),
    y: pxToRem(window.innerWidth < 768 ? 257 : 155),
    deg: -8.87,
    width: 86,
  },
  {
    x: pxToRem(window.innerWidth < 768 ? 320 : 650),
    y: pxToRem(window.innerWidth < 768 ? 75 : 200),
    deg: -5.63,
    width: 71,
  },
]

let themes = [
  { bg: "#CCFFFC", text: "#00322F" },
  { bg: "#F6DBD4", text: "#250B04" },
  { bg: "#F6DBD4", text: "#250B04" },
  { bg: "#FEEACD", text: "#191105" },
  { bg: "#FEEACD", text: "#191105" },
  { bg: "#D4CCFF", text: "#090424" },
  { bg: "#CCFFFC", text: "#00322F" },
  { bg: "#D4CCFF", text: "#090424" },
  { bg: "#FEEACD", text: "#191105" },
]

export default class Word {
  body: Matter.Body
  word: string
  scale: number
  index: number
  p5: p5
  font: p5.Font
  width: number
  constructor(
    word: string,
    index: number,
    pFive: p5,
    world: typeof Matter.World,
    engine: Matter.Engine,
    font: Font
  ) {
    this.p5 = pFive
    this.font = font
    this.scale = window.innerWidth < 768 ? 0.525 : 1.15
    this.width = coords[index].width
    this.index = index
    this.body = Matter.Bodies.rectangle(
      coords[index].x,
      coords[index].y,
      pxToRem(this.width + (innerWidth < 768 ? 34 : 30)) * this.scale,
      pxToRem(window.innerWidth < 768 ? 48 : 37.39 - 1) * this.scale
    )
    this.word = word
    world.add(engine.world, this.body)
  }

  show() {
    let pos = this.body.position
    let angle = this.body.angle || coords[this.index].deg
    this.p5.push()
    this.p5.translate(pos.x, pos.y)
    this.body.angle
      ? this.p5.angleMode("radians")
      : this.p5.angleMode("degrees")
    this.p5.rotate(angle)
    this.p5.rectMode(this.p5.CENTER)
    this.p5.fill(themes[this.index].bg)
    this.p5.stroke("yellow")
    this.p5.strokeWeight(0)
    this.p5.rect(
      0,
      pxToRem(innerWidth < 768 ? 1.5 : 3),
      pxToRem(this.width + (innerWidth < 768 ? 34 : 30)) * this.scale,
      pxToRem(window.innerWidth < 768 ? 48 : 37.39) * this.scale,
      pxToRem(60) * this.scale
    )
    this.p5.noStroke()
    this.p5.textFont(this.font)
    this.p5.fill(themes[this.index].text)
    this.p5.textSize(pxToRem(19.32) * this.scale)
    this.p5.textAlign(this.p5.CENTER, "center")
    this.p5.text(this.word, 0, 0)
    this.p5.pop()
  }
}
