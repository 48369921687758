import { $App } from ".."

export default class Blog {
  app: $App
  constructor(app: $App) {
    this.app = app
  }

  create() {
    const twitterUrl = "https://twitter.com/intent/tweet/"

    function titleCase(str: string) {
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.replace(word[0], word[0].toUpperCase())
        })
        .join(" ")
    }

    function extractTitleText() {
      return titleCase(document.querySelector("h1").innerText)
    }

    function extractAnchorLink() {
      return window.location.href
    }

    function openTwitterWindow(text, link) {
      const twitterQuery = `text=${text}&url=${link}`
      return window.open(`${twitterUrl}?${twitterQuery}&`, "_blank").focus()
    }

    const twitter = window.$(".post__share a:first-of-type svg")
    if (twitter)
      twitter.onclick = (e) => {
        e.preventDefault()
        const text = extractTitleText()
        const link = extractAnchorLink()
        openTwitterWindow(text, link)
      }

    const fb = window.$(".post__share a:nth-of-type(3) svg")
    if (fb)
      fb.onclick = (e) => {
        e.preventDefault()
        const text = extractTitleText()
        const link = extractAnchorLink()
        window
          .open(
            `https://www.facebook.com/sharer/sharer.php?u=${text} ${link}`,
            "_blank"
          )
          .focus()
      }

    const wa = window.$(".post__share a:nth-of-type(2) svg")
    if (wa)
      wa.onclick = (e) => {
        e.preventDefault()
        const text = extractTitleText()
        const link = extractAnchorLink()
        window.open(`https://wa.me/?text=${text} ${link}`, "_blank").focus()
      }
  }

  resize() {}

  destroy() {}

  navigate() {}
}
