import gsap from "gsap"

export function navButtonAnime(btn: Element, index: number) {
  const mm = gsap.matchMedia()
  let tl: gsap.core.Timeline
  tl = gsap.timeline()
  mm.add("(min-width:761px) and (max-width:1919px)", () => {
    if (index === 0) return
    tl = gsap
      .timeline({ paused: true })
      .fromTo(
        btn.querySelector("svg"),
        {
          autoAlpha: 1,
          xPercent: 0,
        },
        {
          autoAlpha: 0,
          xPercent: 100,
          duration: 0.25,
          ease: "ease.in",
        }
      )
      .fromTo(
        btn.querySelector("span"),
        {
          x: 0,
        },
        {
          x: index > 1 ? "1.6rem" : "1.2rem",
          ease: "ease.in",
          duration: 0.25,
          delay: 0.05,
        },
        0
      )
  })

  mm.add("(min-width:1920px)", () => {
    tl = gsap
      .timeline({ paused: true })
      .fromTo(
        btn.querySelector("svg"),
        {
          autoAlpha: 1,
          xPercent: 0,
        },
        {
          autoAlpha: 0,
          xPercent: 100,
          duration: 0.25,
          ease: "ease.in",
        }
      )
      .fromTo(
        btn.querySelector("span"),
        {
          x: 0,
        },
        {
          x: index > 1 ? "1.6rem" : "1.2rem",
          ease: "ease.in",
          duration: 0.25,
          delay: 0.05,
        },
        0
      )
  })

  mm.add("(max-width:760px)", () => {
    tl = gsap.timeline({ delay: 5 })
  })

  return tl
}

export function navLinkAnime(link: Element) {
  const mm = gsap.matchMedia()
  let tl: gsap.core.Timeline
  tl = gsap.timeline()
  mm.add("(min-width:761px) and (max-width:1919px)", () => {
    tl = gsap
      .timeline({ paused: true })
      .fromTo(
        link.querySelector("span"),
        {
          autoAlpha: 1,
          clipPath: "inset(0% 100% 0% 0%)",
        },
        {
          autoAlpha: 1,
          clipPath: "inset(0% 0% 0% 0%)",
          duration: 0.25,
          ease: "ease.in",
        }
      )
      .addPause()
      .fromTo(
        link.querySelector("span"),
        {
          autoAlpha: 1,
          clipPath: "inset(0% 0% 0% 0%)",
        },
        {
          autoAlpha: 1,
          clipPath: "inset(0% 0% 0% 100%)",
          duration: 0.25,
          ease: "ease.out",
        }
      )
  })

  mm.add("(min-width:1920px)", () => {
    tl = gsap.timeline()
  })

  mm.add("(max-width:760px)", () => {
    tl = gsap.timeline({ delay: 5 })
  })

  return tl
}
