import Lenis from "@studio-freight/lenis"
import gsap from "gsap"
import { Observer, ScrollTrigger } from "gsap/all"
gsap.registerPlugin(ScrollTrigger, Observer)

interface LenisEvent {
  animate: {
    value: number
    from: number
    to: number
    lerp: number
    duration: number
    isRunning: boolean
  }
  animatedScroll: number
  dimensions: { wrapper: Window; content: HTMLElement }
  direction: 1 | -1
  options: { wrapper: Window; content: HTMLElement }
  targetScroll: number
  time: number
  velocity: number
  __isLocked: boolean
  __isScrolling: boolean
  __isSmooth: true
  __isStopped: boolean
  actualScroll: number
  className: string
  isHorizontal: boolean
  isLocked: boolean
  isScrolling: boolean
  isSmooth: boolean
  isStopped: boolean
  limit: number
  progress: number
  scroll: number
}

export default class Scroll {
  lenis: Lenis
  scrollbar: gsap.core.Timeline
  observer: Observer
  constructor(page: string) {
    this.create(page)
  }

  create(page: string) {
    this.lenis = new Lenis({
      wrapper: innerWidth >= 768 ? window : window.$(".app"),
      // smoothTouch: true,
      lerp: 0,
    })

    this.scrollbar = gsap.timeline({ paused: true }).to(".header__nav", {
      y: "91vh",
      ease: "linear",
      duration: 5,
    })

    this.observer = Observer.create({
      target: ".header__nav__track",
      onDrag: this.onDrag.bind(this),
      onDragStart: (e) => e.target.classList.add("active"),
      onDragEnd: (e) => e.target.classList.remove("active"),
    })

    this.lenis.on("scroll", ScrollTrigger.update)
    gsap.ticker.lagSmoothing(0)
    requestAnimationFrame(this.raf.bind(this))
  }

  navigate(page: string) {
    this.lenis.reset()
    this.create(page)
  }

  raf(time: number) {
    this.lenis.raf(time)
    this.scrollbar.progress(this.lenis.progress)
    requestAnimationFrame(this.raf.bind(this))
  }

  onDrag(e: globalThis.Observer) {
    this.lenis.scrollTo(
      (e.y / innerHeight) * this.lenis.dimensions.scrollHeight
    )
  }
}
