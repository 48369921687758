import gsap from "gsap"
import { images } from "./utils"
import Framework from "./classes/framework"
import Scroll from "./classes/scroll"
import Home from "./pages/Home"
import Nav from "./partials/nav"
import About from "./pages/About"
import Projects from "./pages/Projects"
import Blog from "./pages/Blog"
import Contact from "./pages/Contact"

declare global {
  interface Window {
    app: App
    $(el: string): HTMLElement
    $$(el: string): HTMLElement[]
  }
}

export interface $App {
  page: Home
  pages: {
    home: Home
    about: About
    projects: Projects
    blog: Blog
    contact: Contact
    // longPage: Home
  }
  partials: { nav: Nav }
  scroll: Scroll
  content: Element
  template: string
  createPages(): void
  createPartials(): void
}

class App extends Framework implements $App {
  pages: {
    home: Home
    about: About
    projects: Projects
    blog: Blog
    contact: Contact
    // longPage: Home
  }
  partials: { nav: Nav }
  page: Home

  constructor() {
    super()
    this.createPages()
    this.createPartials()
    this.preload()
    window.onresize = () => this.onresize()
  }

  preload() {
    const length = images.length
    let counter = 1
    images.forEach((image) => {
      const img = new Image()
      img.src = image
      img.crossOrigin = "anonymous"
      img.onload = () => {
        if (counter === length) {
          window.$(".app").classList.remove("preloading")
        }
        counter++
      }
    })
    // if (images.length === 0 && this.template !== "home")
    // window.$(".app").classList.remove("preloading")
  }

  createPartials() {
    this.partials = {
      nav: new Nav(this),
    }
  }
  createPages() {
    this.pages = {
      home: new Home(this),
      about: new About(this),
      projects: new Projects(this),
      blog: new Blog(this),
      contact: new Contact(this),
      // longPage: new Home(this),
    }
    this.page = this.pages[this.template]
    this.page.create()
  }

  onresize() {
    Object.values(this.pages).forEach((page) => page.resize())
    Object.values(this.partials).forEach((partial) => partial.resize())
  }
}

window.$ = (el: string) => document.querySelector(el)
window.$$ = (el: string) => gsap.utils.toArray(el)
window.app = new App()
