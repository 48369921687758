import gsap from "gsap"
import { $App } from ".."
import testimonialAnime from "../animations/testimonial.anime"
import ImageTransition from "../classes/image-transition"
import p5Text from "../classes/p5-text"
import { themes } from "../classes/sketch"

export default class Home {
  app: $App
  p5: p5Text
  imageTransition: ImageTransition
  canvas: HTMLElement
  mouse: { x: number; y: number }
  canvasParent: HTMLElement
  canvasParentBounds: DOMRect
  projects: HTMLElement[]
  experiences: HTMLElement[]
  constructor(app: $App) {
    this.app = app
  }

  create() {
    this.createP5()
    this.createImageTransition()
    this.createProjects()
    this.createTestimonials()
  }

  createTestimonials() {
    const anime = testimonialAnime()
    anime.progress(0.2)
    const testimonies = window.$$(".home__testimony")
    let tracker = 3
    testimonies[tracker].classList.toggle("active")
    const controls = window.$$(".home__testimonial__controls svg")
    let done = true
    controls.forEach((el, index) => {
      if (index === 0)
        el.onclick = async () => {
          if (innerWidth < 768) {
            anime.reverse()
          } else {
            if (!done) return
            done = false
            testimonies[tracker].classList.toggle("active")
            tracker--
            testimonies[tracker].classList.toggle("active")
            anime.reverse()
            gsap.delayedCall(0.3, () => {
              done = true
            })
          }
        }
      else
        el.onclick = async () => {
          if (innerWidth < 768) {
            anime.play()
          } else {
            if (!done) return
            done = false
            testimonies[tracker].classList.toggle("active")
            tracker++
            testimonies[tracker].classList.toggle("active")
            anime.play()
            gsap.delayedCall(0.3, () => {
              done = true
            })
          }
        }
    })
  }

  createProjects() {
    this.projects = window.$$(".featured__project")
    this.projects.forEach((project) => {
      project.querySelector("button").onclick = () => {
        project.classList.toggle("active")
      }
    })
  }

  createImageTransition() {
    if (innerWidth < 768) {
      this.experiences = window.$$(".home__experience__item")
      this.experiences.forEach((el, index) => {
        el.onclick = () => {
          this.experiences.forEach((el) => el.classList.remove("active"))
          el.classList.add("active")
          gsap.set(".home__experiences", {
            "--bg": themes[index].bg,
            "--bgText": themes[index].bgText,
            "--color": themes[index].color,
            duration: 0.5,
            ease: "ease.inout",
          })
        }
      })
    } else {
      this.imageTransition = new ImageTransition()
      this.mouse = { x: 0, y: 0 }
      this.canvas = window.$("#slider canvas")
      this.canvasParent = window.$(".home__experiences")
      this.canvasParentBounds = this.canvasParent.getBoundingClientRect()
      window.addEventListener("pointermove", (event) => {
        this.mouse.x = event.clientX
        this.mouse.y = event.clientY
        gsap.to(this.canvas, {
          x: this.mouse.x,
          y: this.mouse.y,
          duration: 0.35,
          ease: "ease.inOut",
        })
      })
      window.addEventListener("wheel", (event) => {
        this.mouse.x = event.clientX
        this.mouse.y = event.clientY
        gsap.to(this.canvas, {
          x: this.mouse.x,
          y: this.mouse.y,
          duration: 0.35,
          ease: "ease.inOut",
        })
      })
    }
  }

  createP5() {
    this.p5 = new p5Text(this.app)
    const canvas = window.$(".home__hero__head")
    if (innerWidth < 768) {
      canvas.onpointerenter = () => this.app.scroll.lenis.stop()
      canvas.onpointerleave = () => {
        this.app.scroll.lenis.start()
      }
    }
  }

  resize() {}

  destroy() {}

  navigate() {}
}
