import gsap from "gsap"
import { Power2 } from "gsap"
import {
  DoubleSide,
  Mesh,
  PerspectiveCamera,
  PlaneGeometry,
  Scene,
  ShaderMaterial,
  Texture,
  TextureLoader,
  Vector4,
  WebGLRenderer,
} from "three"
import { pxToRem } from "../utils"

export const themes = [
  { color: "#393939", bg: "#FAC829", bgText: "rgba(57, 57, 57, 0.3)" },
  { color: "#393939", bg: "#FFFFFF", bgText: "rgba(57, 57, 57, 0.3)" },
  { color: "#000000", bg: "#88D34D", bgText: "rgba(108, 156, 70, 0.3)" },
  { color: "#FFFFFF", bg: "#FF0B6F", bgText: "rgba(57, 57, 57, 0.3)" },
  { color: "#FFFFFF", bg: "#9A2BC2", bgText: "rgba(207, 207, 207, 0.6)" },
  { color: "#FFFFFF", bg: "#ED1C24", bgText: "rgba(255, 255, 255, 0.3)" },
  { color: "#FFFFFF", bg: "#000000", bgText: "rgba(255, 255, 255, 0.3)" },
  { color: "#FFFFFF", bg: "#154633", bgText: "rgba(255, 255, 255, 0.3)" },
  { color: "#FFFFFF", bg: "#000000", bgText: "rgba(255, 255, 255, 0.3)" },
  { color: "#FFFFFF", bg: "#000000", bgText: "rgba(255, 255, 255, 0.3)" },
]

export default class Sketch {
  scene: Scene
  vertex: string
  fragment: string
  uniforms: {}
  renderer: WebGLRenderer
  width: number
  height: number
  duration: number
  debug: boolean
  easing: string
  clicker: HTMLElement
  container: HTMLElement
  images: []
  camera: PerspectiveCamera
  time: number
  textures: Texture[]
  current: number
  paused: boolean
  imageAspect: number
  material: ShaderMaterial
  plane: Mesh
  geometry: PlaneGeometry
  isRunning: any
  settings: { progress: number }
  constructor(opts: {
    fragment: string
    uniforms: { intensity: { value: number; min: number; max: number } }
    debug: boolean
    duration?: number
    easing?: string
  }) {
    this.scene = new Scene()
    this.vertex = `varying vec2 vUv;void main() {vUv = uv;gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );}`
    this.fragment = opts.fragment
    this.uniforms = opts.uniforms
    this.renderer = new WebGLRenderer()
    this.width = pxToRem(720)
    this.height = pxToRem(720)
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(this.width, this.height)
    this.renderer.setClearColor(0xeeeeee, 1)
    this.duration = opts.duration || 0.35
    this.debug = opts.debug || false
    this.easing = opts.easing || "easeInOut"

    this.clicker = document.getElementById("content")

    this.container = document.getElementById("slider")
    this.images = JSON.parse(this.container.getAttribute("data-images"))
    this.width = pxToRem(720)
    this.height = pxToRem(720)
    this.container.appendChild(this.renderer.domElement)

    this.camera = new PerspectiveCamera(70, pxToRem(720) / pxToRem(720), 0.001, 1000)

    this.camera.position.set(0, 0, 2)
    this.time = 0
    this.current = 0
    this.textures = []

    this.paused = true
    this.initiate(() => {
      this.setupResize()
      this.set()
      this.addObjects()
      this.resize()
      this.clickEvent()
      this.play()
    })
  }

  initiate(cb) {
    const promises = []
    let that = this
    this.images.forEach((url, i) => {
      let promise = new Promise((resolve) => {
        that.textures[i] = new TextureLoader().load(url, resolve)
      })
      promises.push(promise)
    })

    Promise.all(promises).then(() => {
      cb()
    })
  }

  clickEvent() {
    const experiences = window.$$(".home__experience__item")
    let i: number
    experiences.forEach((exp, index) => {
      exp.onmouseenter = () => {
        this.random(index)
        i = index
        gsap.set(".home__experiences", {
          "--bg": themes[index].bg,
          "--bgText": themes[index].bgText,
          "--color": themes[index].color,
          duration: 0.5,
          ease: "ease.inout",
        })
      }
      exp.onclick = () => {
        exp.querySelector("a").click()
      }
      exp.onwheel = () => {
        if (i !== index) {
          this.random(index)
          i = index
          gsap.set(".home__experiences", {
            "--bg": themes[index].bg,
            "--bgText": themes[index].bgText,
            "--color": themes[index].color,
            duration: 0.5,
            ease: "ease.inout",
          })
        }
      }
    })
  }

  set() {
    let that = this
    // if(this.debug) this.gui = new dat.GUI();
    this.settings = { progress: 0.5 }
    // if(this.debug) this.gui.add(this.settings, "progress", 0, 1, 0.01);

    Object.keys(this.uniforms).forEach((item) => {
      this.settings[item] = this.uniforms[item].value
      // if(this.debug) this.gui.add(this.settings, item, this.uniforms[item].min, this.uniforms[item].max, 0.01);
    })
  }

  setupResize() {
    window.addEventListener("resize", this.resize.bind(this))
  }

  resize() {
    this.width = pxToRem(720)
    this.height = pxToRem(720)
    this.renderer.setSize(this.width, this.height)
    this.camera.aspect = this.width / this.height

    // image cover
    this.imageAspect = pxToRem(720) / pxToRem(720)
    let a1: number
    let a2: number
    if (this.height / this.width > this.imageAspect) {
      a1 = (this.width / this.height) * this.imageAspect
      a2 = 1
    } else {
      a1 = 1
      a2 = this.height / this.width / this.imageAspect
    }

    this.material.uniforms.resolution.value.x = this.width
    this.material.uniforms.resolution.value.y = this.height
    this.material.uniforms.resolution.value.z = a1
    this.material.uniforms.resolution.value.w = a2

    const dist = this.camera.position.z
    const height = 1
    this.camera.fov = 2 * (180 / Math.PI) * Math.atan(height / (2 * dist))

    this.plane.scale.x = this.camera.aspect
    this.plane.scale.y = 1

    this.camera.updateProjectionMatrix()
  }

  addObjects() {
    let that = this
    this.material = new ShaderMaterial({
      extensions: {
        derivatives: true,
      },
      side: DoubleSide,
      uniforms: {
        time: { value: 0 },
        progress: { value: 0 },
        border: { value: 0 },
        intensity: { value: 0 },
        scaleX: { value: 40 },
        scaleY: { value: 40 },
        transition: { value: 40 },
        swipe: { value: 0 },
        width: { value: 0 },
        radius: { value: 0 },
        texture1: { value: this.textures[0] },
        texture2: { value: this.textures[1] },
        displacement: {
          value: new TextureLoader().load("/images/displacement.jpg"),
        },
        resolution: { value: new Vector4() },
      },
      // wireframe: true,
      vertexShader: this.vertex,
      fragmentShader: this.fragment,
    })
    this.geometry = new PlaneGeometry(1, 1, 2, 2)

    this.plane = new Mesh(this.geometry, this.material)
    this.scene.add(this.plane)
  }

  stop() {
    this.paused = true
  }

  play() {
    this.paused = false
    this.render()
  }

  random(index: number) {
    // if (this.isRunning) return
    this.material.uniforms.progress.value = 0
    this.isRunning = true
    let len = this.textures.length
    let nextTexture = this.textures[index]
    this.material.uniforms.texture2.value = nextTexture
    let tl = gsap.timeline()
    tl.set(this.material.uniforms.progress, {
      value: 1,
      duration: this.duration,
      ease: Power2[this.easing],
      onComplete: () => {
        this.material.uniforms.texture1.value = nextTexture
        this.material.uniforms.progress.value = 0
        this.isRunning = false
      },
    })
  }

  next() {}
  render() {
    if (this.paused) return
    this.time += 0.05
    this.material.uniforms.time.value = this.time

    Object.keys(this.uniforms).forEach((item) => {
      this.material.uniforms[item].value = this.settings[item]
    })

    requestAnimationFrame(this.render.bind(this))
    this.renderer.render(this.scene, this.camera)
  }
}
