export function pxToRem(
  pixels: number,
  designWidth = window.innerWidth < 760
    ? 390
    : window.innerWidth >= 1920
    ? 2750
    : 1440,
  innerWidth = window.innerWidth
) {
  return (pixels * innerWidth) / designWidth
}

export const images = []
