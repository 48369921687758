import { $App } from ".."

export default class Projects {
  app: $App
  projects: HTMLElement[]
  constructor(app: $App) {
    this.app = app
  }

  create() {
    this.createProjects()
  }

  createProjects() {
    this.projects = window.$$(".featured__project")
    this.projects.forEach((project) => {
      project.querySelector("button").onclick = () => {
        project.classList.toggle("active")
      }
    })
  }

  resize() {}

  destroy() {}

  navigate() {}
}
