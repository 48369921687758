import gsap from "gsap"
import { $App } from ".."
import { Draggable } from "gsap/all"
import { pxToRem } from "../utils"

gsap.registerPlugin(Draggable)

export default class Contact {
  app: $App
  form: HTMLFormElement

  constructor(app: $App) {
    this.app = app
  }

  create() {
    // gsap.to(".post__ad, .post__bc", {
    //   autoAlpha: 1,
    //   delay: 5,
    //   ease: "expo",
    //   duration: 0.2,
    // })

    // window.$$(".post__ad svg, .post__bc").forEach((el) => {
    //   el.onclick = () =>
    //     gsap.to(".post__ad, .post__bc", {
    //       autoAlpha: 0,
    //       ease: "expo.in",
    //       duration: 0.2,
    //     })
    // })

    const points: HTMLInputElement = gsap.utils.toArray(
      ".slidepoints input[type='radio']"
    ) as unknown as HTMLInputElement
    let draggable = Draggable.create(".contact__right .slidee", {
      type: "x",
      bounds: ".contact__right .slider",
      liveSnap: {
        x: [
          pxToRem(innerWidth < 768 ? 0 : 0),
          pxToRem(innerWidth < 768 ? 60 : 120),
          pxToRem(innerWidth < 768 ? 140 : 260),
          pxToRem(innerWidth < 768 ? 220 : 400),
          pxToRem(innerWidth < 768 ? 300 : 540),
        ],
      },
      onDrag: () => {
        const { minX, maxX, x } = draggable[0]
        const percentage = (x - minX) / (maxX - minX)
        const round = Math.round(percentage * 4)
        points[round].checked = true
      },
    }) as unknown as gsap.core.Timeline

    this.form = document.querySelector("form")
    this.form.onsubmit = (e) => {
      e.preventDefault()
      const form = e.target
      const data = new FormData(this.form)
      const xhr = new XMLHttpRequest()
      xhr.open(this.form.method, this.form.action)
      xhr.setRequestHeader("Accept", "application/json")

      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return
        if (xhr.status === 200) {
          this.form.reset()
          const btn = document.querySelector("#form .home__featured__cta")
          btn.classList.add("active")
          btn.querySelector("span").innerText = "Done"
          draggable[0].kill()
          gsap.set(".contact__right .slidee", {
            x: 0,
            y: 0,
          })
          draggable = Draggable.create(".contact__right .slidee", {
            type: "x",
            bounds: ".contact__right .slider",
            liveSnap: {
              x: [
                pxToRem(innerWidth < 768 ? 0 : 0),
                pxToRem(innerWidth < 768 ? 60 : 120),
                pxToRem(innerWidth < 768 ? 140 : 260),
                pxToRem(innerWidth < 768 ? 220 : 400),
                pxToRem(innerWidth < 768 ? 300 : 540),
              ],
            },
            onDrag: () => {
              const { minX, maxX, x } = draggable[0]
              const percentage = (x - minX) / (maxX - minX)
              const round = Math.round(percentage * 4)
              points[round].checked = true
            },
          }) as unknown as gsap.core.Timeline
        } else {
        }
      }

      xhr.send(data)
    }
  }

  resize() {}

  destroy() {}

  navigate() {}
}
